require('./style.styl')

import gsap from 'gsap'
import {
    ScrollTrigger
} from 'gsap/ScrollTrigger'
import {
    ScrollToPlugin
} from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

console.log('site by lach.io')

setTimeout(() => {
    window.scrollTo(0, 0);
}, 70);

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if(isSafari) {
    document.querySelector('html').classList.add('is-safari')
}

const heroVideo = document.getElementsByClassName('hero-video')[0];

document.querySelector('html').classList.add('lock-scroll')

function loader() {
    const logo = document.getElementsByClassName('logo')
    
    gsap.to('.loader--enter', {
        duration: 0.3,
        ease: 'power3.inOut',
        opacity: 0,
        display: 'none',
        onComplete: () => {
            logo[0].classList.remove('loader-active')
        }
    })

    gsap.to('.loader-bg', {
        duration: 0.6,
        ease: 'power3.inOut',
        opacity: 0,
        display: 'none',
        delay: 0.6,
    })

    gsap.from('nav li', {
        delay: 0.6,
        duration: 0.6, 
        ease: 'power3.inOut',
        stagger: 0.1,
        opacity: 0,
        y: -40,
        onComplete: () => {
            heroVideo.play();
            document.querySelector('html').classList.remove('lock-scroll')
        }
    })
}

gsap.set('.video__play_again', {
    opacity: 0,
    display: 'none',
})

heroVideo.addEventListener('ended', function() {
    scrollToSection('.section__game');
    gsap.to('.video__skip', {
        duration: 0.3, 
        display: 'none',
        opacity: 0
    })
    gsap.to('.video__play_again', {
        duration: 0.3,
        display: 'block',
        opacity: 1
    })
})

document.querySelectorAll('.loader--enter')[0].addEventListener('click', function(e) {
    e.preventDefault();
    loader();
})

document.querySelectorAll('.video__play_again')[0].addEventListener('click', function (e) {
    e.preventDefault();
    heroVideo.currentTime = 0;
    heroVideo.play();

    gsap.to('.video__skip', {
        duration: 0.3,
        display: 'block',
        opacity: 1
    })
    gsap.to('.video__play_again', {
        duration: 0.3,
        display: 'none',
        opacity: 0
    })
})

function skipVideo() {
    heroVideo.currentTime = heroVideo.duration;
}

document.getElementsByClassName('video__skip')[0].addEventListener('click', function(e) {
    e.preventDefault();
    skipVideo();
})

gsap.from(".section__survey h2, .about__text, .survey__link", {
    scrollTrigger: {
        trigger: ".section__survey",
        toggleActions: "restart pause reverse pause"
    },
    stagger: 0.1,
    duration: 0.3,
    y: 80,
    opacity: 0
});

gsap.from("footer p, footer img", {
    scrollTrigger: {
        trigger: "footer",
        toggleActions: "restart pause reverse pause"
    },
    duration: 0.3,
    stagger: 0.1,
    opacity: 0
});

function scrollToSection(element) {
    gsap.to(window, {
        duration: 0.6,
        ease: 'power3.inOut',
        scrollTo: element,
        autoKill: false
    });
}

document.getElementsByClassName('video__skip')[0].addEventListener('click', (e) => {
    e.preventDefault();
    skipVideo();

})

document.querySelectorAll('.js__scroll-to').forEach((element) => {
    element.addEventListener('click', (e) => {
        e.preventDefault();
        if (element.dataset.scrollTo === "section__game") {
            skipVideo();

            if (heroVideo.currentTime === heroVideo.duration) {
                scrollToSection(`.${element.dataset.scrollTo}`);
            }

        } else {
            scrollToSection(`.${element.dataset.scrollTo}`)
        }
    })
})

gsap.set('.game__instructions', {
    display: 'none',
    opacity: 0
})

document.querySelectorAll('.game__instructions_link')[0].addEventListener('click', function (e) {
    e.preventDefault();
    gsap.to('.game__instructions', {
        duration: 0.6,
        ease: 'power3.inOut',
        display: 'flex',
        opacity: 1
    })
})

document.querySelectorAll('.game__instructions_close')[0].addEventListener('click', function (e) {
    e.preventDefault();
    gsap.to('.game__instructions', {
        duration: 0.6,
        ease: 'power3.inOut',
        display: 'none',
        opacity: 0
    })
})